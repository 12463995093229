@import "styles/color.module";
@import "styles/utils.module";

.wrapper {
  width: 100%;
  height: 0;
  position: relative;
  padding-bottom: 56.25%;
  //overscroll-behavior-x: none;
}

.player {
  width: 100%;
  height: 100%;
  position: absolute;

  & > div {
    width: 100%;
    height: 100%;
  }
}


.overlay {
  // TODO: Fill has EDEDED?
  //background: rgba($typo4, 0.4);
  height: 100%;
  width: 100%;
  z-index: 1;
  position: absolute;
  @extend .alignItemsCenter;
  @extend .justifyContentCenter;
  color: #FFFFFF;
  font-weight: bold;
  font-size: 18px;
  line-height: 100%;


  & input[type="range"] {
    padding: 50px 10px;
    background-color: transparent;
    -webkit-appearance: none;
    width: 85%;
    margin-bottom: 0;
  }

  /* Input Thumb */
  & input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background: white;
    cursor: ew-resize;
    background-clip: border-box;
    margin-top: -8px;
  }

  & input[type="range"]::-moz-range-thumb {
    -webkit-appearance: none;
    height: 15px;
    width: 15px;
    border-radius: 50%;
    background: white;
    cursor: ew-resize;
    background-clip: border-box;
    margin-top: -5.5px;
  }

  & input[type="range"]::-ms-thumb {
    -webkit-appearance: none;
    height: 15px;
    width: 15px;
    border-radius: 50%;
    background: white;
    cursor: ew-resize;
    background-clip: border-box;
    margin-top: -5.5px;
  }

  //& input[type="range"]::-webkit-slider-thumb:hover {
  //  background: none;
  //}
  //
  //& input[type="range"]::-moz-range-thumb:hover {
  //  background: none;
  //}
  //
  //& input[type="range"]::-ms-thumb:hover {
  //  background: none;
  //}

  /* Input Track */
  & input[type=range]::-webkit-slider-runnable-track  {
    -webkit-appearance: none;
    box-shadow: none;
    border: none;
    background: rgba(255, 255, 255, 0.6);
    background-image: linear-gradient($brand, $brand);
    background-size: var(--input-range-slider, 0) 100%;
    background-repeat: no-repeat;
    height: 3px;
  }

  & input[type=range]::-moz-range-track {
    -webkit-appearance: none;
    box-shadow: none;
    border: none;
    background: rgba(255, 255, 255, 0.6);
    background-image: linear-gradient($brand, $brand);
    background-size: var(--input-range-slider, 0) 100%;
    background-repeat: no-repeat;
    height: 3px;
  }

  & input[type="range"]::-ms-track {
    -webkit-appearance: none;
    box-shadow: none;
    border: none;
    background: rgba(255, 255, 255, 0.6);
    background-image: linear-gradient($brand, $brand);
    background-size: var(--input-range-slider, 0) 100%;
    background-repeat: no-repeat;
    height: 3px;
  }
}

.slider {
  width: 80%;
  height: 80px;
  position: absolute;
  bottom: 0;
}



.iconWrapper {
  border-radius: 9999px;
  background-color: rgba($typo1, 0.35);
  width: 48px;
  height: 48px;
  @extend .alignItemsCenter;
  @extend .justifyContentCenter;
}

.video {
  & video {
    object-fit: cover;
  }
}

.muteButton {
  font-weight: bold;
  font-size: 14px;
  line-height: 140%;
  color: $typo4;
  top: 20px;
  right: 20px;
  position: absolute;
  background-color: $lightBackground;
  padding: 7px 20px;
  border-radius: 5px;
}
