@import "styles/utils.module.scss";
@import "styles/color.module";

@each $name, $map in $themes {
  .theme-#{$name} {
    @each $key, $value in $map {
      --#{$key}: #{$value};
    }
  }
}

.infoContainer {
  flex: 1;
}

.name {
  color: $typo4;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  //word-break: keep-all;
  //word-wrap: break-word;
  white-space: pre-wrap;
  word-break: break-all;
  @extend .no-select;
}

@include tablet {
  .name {
    font-size: 15px;
  }
}

.fontStyle {
  font-weight: bold;
  font-size: 20px;
  line-height: 100%;
  color: $brand;
  @extend .no-select;
}

.mediaIcon {
  position: absolute;
  left: 12px;
  top: 12px;
  z-index: 2;
}

.miniMediaIcon {
  left: 6px;
  top: 6px;
  width: 16px;
  height: 16px;
}

.likeContainer {
  position: absolute;
  right: 8px;
  bottom: 8px;
  z-index: 2;
}

.miniLikeContainer {
  right: 5px;
  bottom: 5px;
  top: unset;
}

@include mobile {
  .fontStyle {
    font-size: 18px;
  }
}

.likeIcon {}

.defaultImage {
  background: rgba($typo4, 0.4);
  height: 100%;
  width: 100%;
  z-index: 1;
  position: absolute;
  @extend .alignItemsCenter;
  @extend .justifyContentCenter;
  color: #FFFFFF;
  font-weight: bold;
  line-height: 100%;
  border-radius: 5px;
}

.content {
  display: block;
  font-size: 16px;
  line-height: 140%;
  color: $typo4;
  font-weight: 500;
  white-space: pre-wrap;
}

@include tablet {
  .content {
    font-size: 15px;
  }
}

.hashTag {
  color: $hashTag;
}

.overlay {
  //background: rgba($typo4, 0.4);
  height: 100%;
  width: 100%;
  z-index: 1;
  position: absolute;
  @extend .alignItemsCenter;
  @extend .justifyContentCenter;
  color: #FFFFFF;
  font-weight: bold;
  font-size: 18px;
  line-height: 100%;
}

.inlineProductContainer {
  position: absolute;
  bottom: 8px;
  left: 8px;
}

.inlineProductImage {
  border-radius: 9999px;
  border: 1px solid $brand;
  overflow: hidden;
  width: 44px;
  height: 44px;
  position: relative;
}

.inlineProductPlusIcon {
  position: absolute;
  width: 14px;
  height: 14px;
  right: -2px;
  top: 0;
}

.productContainer {
  display: flex;
  align-items: center;
  align-self: stretch;
}

.productWrapper {
  position: relative;
}

.productImage {
  border-radius: 9999px;
  border: 0.5px solid $brand;
  overflow: hidden;
  width: 32px;
  height: 32px;
  margin-right: 4px;
  @extend .alignItemsCenter;
  @extend .cursorPointer;
  position: relative;
}

.textContainer {
  @extend .flexRow;
  margin-top: 12px;
  align-items: flex-start;
}

.showProductPostText {
  font-weight: 500;
  font-size: 14px;
  line-height: 120% !important;
  color: $typo4;
}

.descriptionText {
  color: var(--typo4);
}

.plusIcon {
  position: absolute;
  right: 2px;
  top: -2px;
}

.placeholder {
  height: 20px;
  background: $border;
}

.placeholderProduct {
  background: $border;
}

.placeholderProductWrapper {
  background: $border;
  border: none;
}

.placeholderImage {
  background: $border;
}

.placeholderText {
  background: $border;
  height: 20px;
  margin-left: 3px;
  border-radius: 5px;
}

.profileContainer {
  @extend .flexRow;
  @extend .alignItemsCenter;
  position: absolute;
  bottom: 6px;
  left: 8px;
}

.userInfoContainer {
  flex: 1;
  margin-left: 4px;
  @extend .flexRow;
  @extend .alignItemsCenter;
}
.profileImage {
  border-radius: 9999px;
  overflow: hidden;
  @extend .alignItemsCenter;
  width: 16px;
  height: 16px;
  @extend .cursorPointer;
}

.userNickname {
  font-weight: 700;
  font-size: 10px;
  line-height: 100%;
  color: #FFFFFF;
  vertical-align: middle;
  margin-right: 4px;
}

.creatorBadge {
  width: 8px;
  height: 8px;
}
